import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import Carousel from "@brainhubeu/react-carousel";

import "@brainhubeu/react-carousel/lib/style.css";

import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import VideoBackgroundImg from "../img/backgroundVideo.png";
import ArrowImgMap from "../img/arrows-48-48-3.png";

const SliderContainer = styled.div`
  background-image: url(${VideoBackgroundImg});
  background-repeat: no-repeat;
`;

const StyledVimeo = styled(Vimeo)`
  margin-top: 14px;
`;

const ArrowRight = styled.div`
  position: absolute;
  right: -18px;
  top: -240px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  background: url(${ArrowImgMap}) right top no-repeat;
  display: block;
`;
const ArrowLeft = styled.div`
  position: absolute;
  left: -18px;
  top: -240px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  background: url(${ArrowImgMap}) left top no-repeat;
  display: block;
`;

const mod = (x, n) => ((x % n) + n) % n;

const AUTOPLAY_MS = 5000;

export const IndexPageTemplate = ({ videos, content }) => {
  const [sliderIdx, setSliderIdx] = useState(0);
  const [paused, setPaused] = useState(true);

  const requestRef = useRef();
  const lastTimeRef = useRef(Date.now());

  const loop = () => {
    requestRef.current = requestAnimationFrame(loop);

    const current = Date.now();
    const elapsed = current - lastTimeRef.current;

    if (elapsed > AUTOPLAY_MS) {
      setSliderIdx((prevState) => mod(prevState + 1, videos.length));
      lastTimeRef.current = current;
    }
  };

  useEffect(() => {
    if (!paused) {
      cancelAnimationFrame(requestRef.current);
      return;
    }

    lastTimeRef.current = Date.now();
    requestRef.current = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(requestRef.current);
  }, [paused]);

  return (
    <div>
      <SliderContainer>
        <Carousel
          value={sliderIdx}
          slides={
            videos &&
            videos.map(({ id, frontmatter: { videoUrl } }, videoIdx) => (
              <StyledVimeo
                key={id}
                video={videoUrl}
                width="853px"
                height="486px"
                paused={sliderIdx !== videoIdx || paused}
                onPause={() => {
                  console.log("onPause");
                  setPaused(true);
                }}
                onPlay={() => {
                  console.log("onPlay");
                  setPaused(false);
                }}
              />
            ))
          }
        />
        <div style={{ position: "relative" }}>
          <ArrowLeft
            onClick={() => {
              setSliderIdx(mod(sliderIdx - 1, videos.length));

              // Reset auto play of slider
              lastTimeRef.current = Date.now();
            }}
          />
          <ArrowRight
            onClick={() => {
              setSliderIdx(mod(sliderIdx + 1, videos.length));

              // Reset auto play of slider
              lastTimeRef.current = Date.now();
            }}
          />
        </div>
        <div style={{ padding: "1rem" }} />
      </SliderContainer>
      <HTMLContent className="content" content={content} />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        videoUrl: PropTypes.string,
      }),
    })
  ),
};

const IndexPage = ({ data }) => {
  const { fields, html } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate videos={fields.videos} content={html} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      fields {
        videos {
          id
          frontmatter {
            title
            videoUrl
          }
        }
      }
    }
  }
`;
